.network-chart-container {
    position: relative;
    height: 90vh;
}

.network-chart-container .detailed-info {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 350px;
    z-index: 2;
    text-align: left;
}

.network-chart-container .detailed-info .detail-text {
    /* float: left; */
    text-align: left;
    display: inline-block;
    /* height: 100px; */
    /* line-height: 10px; */
}

.network-chart-container .network-chart {
    position: absolute;
    left: 0;
    z-index: 1;
    background-color: #212529;
    width: 100%;
    height: 100%;
}

.network-chart-container .network-map {
    z-index: 1;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
}

.network-chart-container .network-map .leaflet-container {
    width: 100%;
    height: 100%;
}

.network-chart-container .network-chart .node {
    cursor: pointer;
}

.path-tooltip {
    text-align: left;
    display: none;
    z-index: 9999;
}

.isolated-link {
    display: block;
}

.isolated-hover-show {
    display: block;
    opacity: 0.5;
}

.highlight-link {
    display: block;
    stroke-width: 5px;
}

.isolated-node {
    display: block;
}
