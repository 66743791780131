.hidden {
    display: none;
}
.visible {
    display: block;
}

.detailed-info .field-detail {
    background: none;
    text-align: left;
}
.detailed-info .detail-fields {
    max-height: 400px;
}

.relationship-category,
.relationship-label {
    margin-bottom: 0px;
}

.map-label {
    border: none !important;
    color: #fff !important;
    background: none !important;
    box-shadow: none !important;
}

.map-label:before {
    content: none !important;
}

.map-arrow {
    z-index: 0;
}

.map-node {
    z-index: 2;
}

.map-edge {
    z-index: 1;
}
