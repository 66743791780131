.thick-stroke {
  stroke-width: 10px; }

.main-nav {
  margin-bottom: 0px; }
  .main-nav .navbar-nav li {
    cursor: pointer; }
  .main-nav .navbar-brand.active {
    color: #555;
    background-color: #e7e7e7; }

.za-fixed-header {
  position: fixed;
  top: 0px;
  width: 100%;
  background: #fff; }

.below-nav {
  margin-top: 80px; }

.nav-brand {
  float: left; }

.nav-link.product {
  float: left;
  letter-spacing: 2px; }

.nav-link.user {
  float: right;
  letter-spacing: 1px; }

.za-action-group {
  position: fixed;
  top: 70px;
  left: 10px;
  width: 180px; }
  .za-action-group .za-action {
    background: #3498db;
    border-radius: 5px;
    margin-bottom: 20px;
    color: #ecf0f1;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 20px;
    padding: 15px 15px;
    text-align: center; }
    .za-action-group .za-action:hover {
      background: #2980b9; }

.za-search input {
  margin-bottom: 5px; }

.auto-complete {
  max-height: 210px;
  overflow: auto; }

.za-search .auto-complete li {
  font-size: 14px;
  overflow: hidden;
  padding: 0px 5px;
  max-width: 150px;
  text-align: left;
  text-overflow: ellipsis; }
  .za-search .auto-complete li:hover {
    background: #c0392b; }
  .za-search .auto-complete li .active {
    color: #f1c40f; }

.za-action-popup {
  position: fixed;
  top: 70px;
  left: 230px; }

.repeat-enter {
  -webkit-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  -moz-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  -o-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  height: 0;
  position: relative; }

.circle-group, path, .text-group {
  cursor: pointer; }
  .circle-group.inactive, path.inactive, .text-group.inactive {
    visibility: hidden; }
    .circle-group.inactive.hover, path.inactive.hover, .text-group.inactive.hover {
      visibility: visible; }
      .circle-group.inactive.hover circle:first-child, path.inactive.hover circle:first-child, .text-group.inactive.hover circle:first-child {
        fill: #e74c3c; }
  .circle-group.active circle:first-child, path.active circle:first-child, .text-group.active circle:first-child {
    fill: #9ed8ff; }
  .circle-group.active.hover circle:first-child, path.active.hover circle:first-child, .text-group.active.hover circle:first-child {
    fill: #f1c40f; }
  .circle-group.hover circle:first-child, path.hover circle:first-child, .text-group.hover circle:first-child {
    fill: #9ed8ff; }
  .circle-group.hover .node-name, path.hover .node-name, .text-group.hover .node-name {
    fill: #666666;
    font-size: 18px;
    letter-spacing: 3px;
    stroke-width: 0.5px; }

path.hover {
  stroke-width: 4px; }
  path.hover.inactive {
    stroke-width: 1px; }
  path.hover.active {
    stroke-width: 4px; }

path {
  cursor: pointer; }

.text-group {
  font-size: 20px;
  letter-spacing: 0;
  pointer-events: none;
  fill: #bbb; }
  .text-group .node-name-background {
    visibility: hidden; }
  .text-group.active .node-name {
    fill: #666666;
    font-size: 18px;
    stroke: #000;
    fill: #155373 !important;
    stroke-width: 0.5px;
    letter-spacing: 3px; }

.detailed-info {
  position: fixed;
  top: 70px;
  right: 10px; }
  .detailed-info .company-detail, .detailed-info .relationship-detail {
    float: right;
    margin-right: 15px;
    width: 350px;
    overflow: auto; }
  .detailed-info .company-detail {
    right: 10px; }
  .detailed-info .relationship-detail .detail-title {
    font-size: 16px; }
  .detailed-info .detail-title {
    background: #efefef;
    border-radius: 8px;
    font-weight: bold;
    margin: 0px 0px 10px 0px;
    padding: 10px;
    text-transform: capitalize; }
  .detailed-info .detail-fields {
    padding: 8px 0px 0px;
    border-radius: 8px;
    background: #aaa;
    max-height: 500px;
    overflow: auto; }
  .detailed-info .company-name {
    color: #16a085;
    font-size: 18px; }
  .detailed-info .relationship-edge {
    color: #999999; }
  .detailed-info .relationship-category {
    color: #bbbbbb; }
  .detailed-info .field-detail {
    padding: 10px 15px;
    background: #ccc; }
  .detailed-info .detail-header {
    color: #fff;
    font-size: 10px;
    font-weight: lighter;
    letter-spacing: 2px;
    text-transform: uppercase; }

.highlighter .panel-body {
  background: lavender; }

.add-highlight {
  min-width: 240px;
  padding: 15px 15px 15px 0px; }
  .add-highlight select {
    border-radius: 0; }
  .add-highlight label {
    color: #aaaaaa;
    margin-left: 5px;
    margin-bottom: 2px;
    margin-top: 0px;
    font-weight: normal; }
  .add-highlight li {
    margin-bottom: 5px; }
  .add-highlight .btn-add-highlight {
    color: #666666; }

.active-highlights {
  padding: 15px 0; }

.active-highlight-header {
  color: #aaaaaa;
  margin-bottom: 5px; }

.active-highlight {
  margin-bottom: 5px; }

.active-highlight-text {
  padding: 5px 7px;
  border-radius: 5px;
  margin-left: 5px;
  margin-bottom: 5px; }

.highlight-inline {
  display: inline-block; }

.active-highlight .close {
  margin-top: 3px;
  margin-left: 8px; }

.color-selector {
  margin-bottom: 10px; }

.color {
  cursor: pointer;
  float: left;
  height: 25px;
  margin-right: 3px;
  width: 25px; }
  .color:hover {
    opacity: 0.5; }
  .color .selected {
    border-bottom: 5px solid #FFFFFF; }

.path-tooltip {
  position: absolute;
  top: 500px;
  left: 20px;
  background: #efefef;
  padding: 10px;
  border-radius: 8px; }

.path-tooltip-source {
  margin-right: 5px;
  white-space: nowrap; }

.path-tooltip-target {
  margin-left: 5px;
  white-space: nowrap; }

.za-tooltip-title {
  text-transform: uppercase;
  margin-bottom: 5px; }

.za-tooltip-sub-title {
  margin-bottom: 5px; }

.za-strong {
  font-weight: bold; }

.pruner {
  border-radius: 5px;
  line-height: 35px; }
  .pruner .za-panel-body {
    padding-top: 25px;
    background: lavender; }

.za-filter {
  margin-top: 3px; }

.za-rule {
  width: 50px; }

.subfilters {
  margin-top: 15px;
  margin-left: 40px; }

.filter-grid {
  margin-right: 5px;
  float: left; }
  .filter-grid select {
    min-width: 80px; }

.filter-text {
  font-size: 18px;
  letter-spacing: 0px;
  color: #666;
  margin-left: 10px; }

.filter-number {
  font-weight: bold;
  font-size: 22px;
  color: #666; }

.filter-button-group {
  float: right;
  margin-left: 30px; }
  .filter-button-group button {
    margin-left: 10px; }
    .filter-button-group button.close {
      margin-top: 7px; }

.filter-main-button-group .za-btn-reset, .filter-main-button-group .za-btn-clear {
  float: right; }

.filter-main-button-group .za-btn-reset {
  margin-left: 10px; }

.admin-interface {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px; }

.login {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px; }

.za-login-button {
  margin-right: 10px; }

.user-info {
  cursor: pointer; }
  .user-info:hover {
    background: #efefef; }

.za-header {
  background: #3498db;
  padding: 30px 50px; }
  .za-header.za-header-large {
    padding: 60px; }

.za-title {
  color: #ecf0f1; }
  .za-title .za-title-prefix {
    float: left;
    font-size: 24px;
    padding-right: 10px;
    text-align: right;
    width: 200px;
    color: yellowgreen; }
  .za-title.za-title-medium {
    font-size: 40px; }
  .za-title.za-title-large {
    font-size: 60px;
    letter-spacing: 4px; }

.za-sub-title {
  color: #ecf0f1;
  font-size: 24px;
  font-weight: lighter;
  letter-spacing: 1px; }

.za-section, .za-about-section, .za-about-header {
  padding: 30px 60px; }

.za-about-section, .za-about-header {
  font-size: 20px;
  font-weight: lighter; }
  .za-about-section p, .za-about-header p {
    max-width: 640px;
    margin: 0px auto 10px; }
  .odd.za-about-section, .odd.za-about-header {
    background: #3498db;
    color: #ecf0f1; }
  .even.za-about-section, .even.za-about-header {
    background: #fff;
    color: #34495e; }
  .za-mission-statement.za-about-section, .za-mission-statement.za-about-header {
    font-size: 20px; }
    .za-mission-statement.za-about-section .za-about-subject, .za-mission-statement.za-about-header .za-about-subject {
      color: #ecf0f1;
      font-size: 36px; }

.za-about-header {
  font-size: 18px; }

.za-about-subject {
  color: #34495e;
  font-size: 24px;
  font-weight: bold; }

.za-about-content {
  text-align: justify; }

.za-body {
  margin-top: 0px; }

.btn-close {
  background: #999;
  color: white;
  border-color: #888; }

body {
  font-family: 'Open Sans'; }

.sightline-quickstart {
  background: #3498db;
  padding: 20px 0px; }
  .sightline-quickstart iframe {
    display: block;
    margin: 50px auto; }

.actionate {
  padding: 50px; }

@media (max-width: 990px) {
  .we-do {
    padding: 0px 10%;
    margin-top: 40px; } }

h3 {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 4px; }

.what-we-do {
  color: #777; }
  .what-we-do .glyphicon {
    margin-right: 15px;
    font-size: 24px;
    color: #aaa; }

.how-we-do {
  font-size: 20px;
  color: #555;
  text-align: justify; }

.filters {
  width: 70%; }

.dropdown-menu > li > a {
  max-width: 300px; }

.highlight-category-dwn {
  z-index: 1000000000000;
  height: 300px;
  overflow: scroll; }

.contactpage h3 {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #636363;
  letter-spacing: 0px; }

.contactpage textarea {
  resize: none;
  width: 100%;
  height: 120px;
  border: 3px solid #cccccc;
  padding: 5px;
  font-family: Tahoma, sans-serif; }

.contactpage .card .card-body {
  background: #b6daf2; }

.home-carousel {
  height: 400px; }
  .home-carousel img {
    height: 400px;
    width: 900px; }
  .home-carousel h4 {
    color: #636363;
    font-size: 30px; }
